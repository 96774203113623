<section id="header">
  <div class="container">
    <div class="navbar">
      <div class="logo">
        <img src="/assets/images/logo.svg" alt="logo" />
      </div>
      <div class="login  {{ login_flag ? 'd-none' : 'd-flex' }}">
        <p (click)="openDialog('login')">Login</p>
        <p>|</p>
        <p (click)="openDialog('register')">New user</p>
      </div>
      <div class="login  {{ login_flag ? 'd-flex' : 'd-none' }}">
        <p (click)="log_out()">Logout</p>
      </div>
    </div>
  </div>
</section>

<section id="banner">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="title mt-3 mt-lg-5">
          <h1>
            Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing
          </h1>
        </div>
        <div class="text">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut
            luctus nisi. In commodo magna lectus.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="banner_image">
          <img
            src="/assets/images/banner.svg"
            class="mt-lg-4 object-fit"
            alt="banner-image"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section id="details_section" class="my-lg-4 my-md-3">
  <div class="container">
    <div class="accord">
      <div class="container">
        <div class="row bg_color_blue">
          <div class="{{ show_map ? 'col-md-4' : 'col-md-12' }}">
            <div
              class="my-3 my-lg-0"
              [ngStyle]="{
                width:
                  !show_map && innerWidth >= 768 && innerWidth <= 991
                    ? '48%'
                    : '',
                margin:
                  !show_map && innerWidth >= 768 && innerWidth <= 991
                    ? 'auto'
                    : ''
              }"
            >
              <form
                id="search"
                [formGroup]="trainForm"
                (keydown.enter)="$event.preventDefault()"
                class="{{
                  show_map || innerWidth <= 767 ? 'd-grid' : 'd-flex '
                }} justify-content-between align-items-center py-lg-4 px-lg-4"
              >
                <div
                  class="input_wrap  {{ show_map ? 'mb-4' : 'mb-lg-0' }}"
                  [ngStyle]="{
                    display: show_map || innerWidth <= 767 ? 'grid' : ''
                  }"
                  [ngStyle]="{
                    width: !show_map && innerWidth >= 1400 ? '28%' : '28%'
                  }"
                >
                  <label [hidden]="!show_map">Pickup Location</label>
                  <input
                    class="  "
                    [ngStyle]="{
                      width:
                        show_map || innerWidth <= 767 || innerWidth >= 1400
                          ? 'unset'
                          : '100%'
                    }"
                    [ngStyle]="{
                      width: !show_map && innerWidth >= 1400 ? '100%' : ''
                    }"
                    type="text"
                    name="From"
                    formControlName="from"
                    [(ngModel)]="from_location.place_name"
                    autocomplete="off"
                    (input)="auto_complete($event, 'from', true)"
                    placeholder="Pickup Location"
                    (keydown)="auto_complete($event, 'from', false)"
                  />
                  <span
                    class="error"
                    *ngIf="errorControl.from.errors && search_submitted"
                  >
                    Please enter your source location
                  </span>
                  <ul
                    class="searchlist"
                    [ngStyle]="{
                      top: !show_map || innerWidth <= 991 ? '3.4rem' : '5.4rem'
                    }"
                    *ngIf="from_flag"
                  >
                    <li
                      *ngFor="let i of from_list; index as j"
                      value="{{ i }}"
                      (click)="select_location(i, 'from')"
                    >
                      <ul>
                        <li>{{ i.place }}</li>
                        <li>{{ i.location_name }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div
                  [hidden]="show_map"
                  class="{{
                    !show_map && innerWidth <= 767
                      ? 'd-none d-lg-flex justify-content-center align-items-center'
                      : ''
                  }}"
                >
                  <img
                    src="/assets/images/Vector.svg"
                    [ngStyle]="{
                      width: innerWidth <= 767 ? '9%' : '',
                      margin: innerWidth <= 767 ? '.9rem' : ''
                    }"
                  />
                </div>
                <div
                  class="input_wrap mb-4 mt-4 mt-lg-0 {{
                    show_map ? '' : 'mb-lg-0 '
                  }} "
                  [ngStyle]="{
                    display: show_map || innerWidth <= 767 ? 'grid' : ''
                  }"
                  [ngStyle]="{
                    width: !show_map && innerWidth >= 1400 ? '28%' : '28%'
                  }"
                >
                  <label [hidden]="!show_map">Drop Location</label>
                  <input
                    class=""
                    [ngStyle]="{
                      width:
                        show_map || innerWidth <= 767 || innerWidth >= 1400
                          ? 'unset'
                          : '100%'
                    }"
                    [ngStyle]="{
                      width: !show_map && innerWidth >= 1400 ? '100%' : ''
                    }"
                    type="text"
                    (input)="auto_complete($event, 'to', true)"
                    name="to"
                    formControlName="to"
                    [(ngModel)]="to_location.place_name"
                    placeholder="Drop Location"
                    autocomplete="off"
                    (keydown)="auto_complete($event, 'to', false)"
                  />
                  <span
                    class="error"
                    *ngIf="errorControl.to.errors && search_submitted"
                  >
                    Please enter your destination location
                  </span>
                  <ul
                    class="searchlist"
                    [ngStyle]="{
                      top: !show_map || innerWidth <= 991 ? '3.4rem' : '5.4rem'
                    }"
                    *ngIf="to_flag"
                  >
                    <li
                      *ngFor="let i of to_list; index as j"
                      value="{{ i }}"
                      (click)="select_location(i, 'to')"
                    >
                      <ul>
                        <li>{{ i.place }}</li>
                        <li>{{ i.location_name }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div
                  class="input_wrap mb-4  {{ show_map ? '' : 'mb-lg-0' }}"
                  [ngStyle]="{
                    display: show_map || innerWidth <= 767 ? 'grid' : '',
                    width:
                      !show_map && innerWidth >= 1400
                        ? '20%'
                        : !show_map && innerWidth <= 1399
                        ? '23%'
                        : 'unset'
                  }"
                >
                  <label [hidden]="!show_map">Pickup Date</label>
                  <input
                    id="datepicker"
                    class=" {{ date_place ? 'date' : '' }} "
                    [ngStyle]="{
                      width:
                        show_map || innerWidth <= 767 || innerWidth >= 1400
                          ? 'unset'
                          : '100%'
                    }"
                    [ngStyle]="{
                      width: !show_map && innerWidth >= 1400 ? '100%' : ''
                    }"
                    (change)="select($event)"
                    type="date"
                    name="date"
                    formControlName="date"
                    placeholder="Pickup Date"
                  />
                  <!-- <span
                    class="error"
                    *ngIf="errorControl.date.errors && search_submitted"
                  >
                    This field is required
                  </span> -->
                </div>
                <button
                  mat-stroked-button
                  (click)="toggleAccordian($event)"
                  class="close"
                  [ngStyle]="{
                    width: show_map || innerWidth <= 767 ? '33%' : '',
                    margin: show_map || innerWidth <= 767 ? 'auto' : ''
                  }"
                >
                  Search
                </button>
                <!-- <button (click)="toggleAccordian($event)" class="close">Search</button> -->
              </form>
            </div>
          </div>
          <div
            class="col-md-8 {{
              show_map ? 'd-flex align-items-center justify-content-center' : ''
            }} my-3 my-lg-0 p-lg-4"
            [hidden]="!show_map"
          >
            <div class="map" id="map" class="match-parent"></div>
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7887.910978523123!2d77.72649885682374!3d8.695777049253191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1637751302756!5m2!1sen!2sin"
              width="650"
              height="350"
              style="border: 0;"
              allowfullscreen=""
              loading="lazy"
            ></iframe> -->
          </div>
        </div>
      </div>

      <div class="panel" id="panel" hide="!isActive">
        <div class="row px-lg-5 mx-lg-2 py-4 py-lg-5 mx-1">
          <div class="col-md-5 px-lg-5">
            <div class="text m-0">
              <h3>Cargo Details</h3>
              <p class="sub_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </p>
            </div>
            <form [formGroup]="cargoform" id="cargo">
              <!-- <mat-form-field class="example-full-width" appearance="fill">
                <input
                  matInput
                  maxlength=""
                  placeholder="What do you want to transport?"
                />
              </mat-form-field> -->
              <div>
                <input
                  type="text"
                  formControlName="type"
                  placeholder="What do you want to transport?"
                />
                <span
                  class="error"
                  *ngIf="cargoControl.type.errors && check_submitted"
                >
                  Please enter your cargo detail
                </span>
              </div>

              <!-- <input type="text" placeholder="Package Type" /> -->
              <div>
                <mat-form-field appearance="fill">
                  <mat-select
                    [(ngModel)]="selectedValue"
                    name="package"
                    formControlName="package_type"
                    placeholder="Package Type"
                  >
                    <mat-option
                      *ngFor="let package of packages"
                      [value]="package.value"
                    >
                      {{ package.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span
                  class="error"
                  *ngIf="cargoControl.package_type.errors && check_submitted"
                >
                  Please select a package type
                </span>
              </div>
              <div>
                <input
                  id="input_size"
                  type="text"
                  placeholder="Dimension of Package"
                  [(ngModel)]="size_value"
                  autocomplete="off"
                  readonly
                  value="{{ size_value }}"
                  [ngModelOptions]="{ standalone: true }"
                  (click)="calculate_size('true', $event)"
                />
                <span
                  class="error"
                  *ngIf="
                    (cargoControl.length.errors && check_submitted) ||
                    (cargoControl.breath.errors && check_submitted) ||
                    (cargoControl.height.errors && check_submitted)
                  "
                >
                  Please enter the dimensions of your package in cm
                </span>
                <div id="size_input" class="size_input">
                  <span id="size_input" class="input">
                    <input
                      class="aftercontent"
                      type="number"
                      formControlName="length"
                      placeholder="L"
                      id="size_input"
                      [maxlength]="4"
                      (focusout)="dimension_cal()"
                      (input)="hide_cms($event)"
                    />
                  </span>

                  <span id="size_input">X</span>
                  <span id="size_input" class="input">
                    <input
                      type="number"
                      formControlName="breath"
                      placeholder="B"
                      id="size_input"
                      [maxlength]="4"
                      (focusout)="dimension_cal()"
                      (input)="hide_cms($event)"
                    />
                  </span>
                  <span id="size_input">X</span>
                  <span id="size_input" class="input">
                    <input
                      type="number"
                      formControlName="height"
                      placeholder="H"
                      id="size_input"
                      [maxlength]="4"
                      (focusout)="dimension_cal()"
                      (input)="hide_cms($event)"
                    />
                  </span>
                </div>
              </div>
              <div>
                <input
                  type="number"
                  formControlName="count"
                  placeholder="Number of Packages"
                />
                <!-- (focusout)="append_unit()" -->
                <span
                  class="error"
                  *ngIf="cargoControl.count.errors && check_submitted"
                >
                  Please enter the number of
                  {{ unit_of_pack == '' ? 'packages' : unit_of_pack }}
                </span>
              </div>

              <div class="d-flex align-items-center w-100">
                <div class="load">
                  <input
                    type="number"
                    placeholder="Load"
                    formControlName="load"
                    class="w-60"
                  />
                  <span
                    class="error"
                    *ngIf="cargoControl.load.errors && check_submitted"
                  >
                    Please enter the total weight of your cargo
                  </span>
                </div>

                <mat-radio-group
                  aria-label="Select an option"
                  formControlName="unit"
                >
                  <mat-radio-button value="Tonne">
                    Tonne
                  </mat-radio-button>
                  <mat-radio-button value="Kg" [checked]="true">
                    Kilograms
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <div
                class="d-flex justify-content-center align-items-center mt-5"
              >
                <!-- <button class="close">Refresh availability</button> -->
                <button
                  mat-stroked-button
                  class="close"
                  type="submit"
                  (click)="refresh_availability()"
                >
                  Check Availability
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-7 mt-4 mt-lg-0 mt-md-0">
            <div class="text m-0">
              <h3>Availability</h3>
              <p class="sub_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </p>
              <div class="table table-responsive-md availability">
                <table class="booknow_table  {{ !show_message ? 'blur' : '' }}">
                  <tbody>
                    <tr
                      *ngFor="let item of availabilitylist"
                      class="{{ item.booking_status ? 'green' : 'red' }}"
                    >
                      <td>
                        <img
                          src="/assets/images/{{
                            item.booking_status
                              ? 'calender_green.svg'
                              : 'calender.svg'
                          }}"
                        />
                        {{ item.date }}
                        <button
                          [ngStyle]="{
                            display: show_map && innerWidth <= 422 ? '' : 'none'
                          }"
                          mat-stroked-button
                          class="book dublicate {{
                            item.booking_status ? 'close' : ''
                          }}"
                          [disabled]="!item.booking_status || !button_none"
                          (click)="booking(item)"
                        >
                          Calculate Price
                        </button>
                      </td>
                      <td>
                        <img
                          src="/assets/images/{{
                            item.booking_status
                              ? 'weiging_green.svg'
                              : 'weiging.svg'
                          }}"
                        />
                        {{ item.available_weight }} Kg
                        <div
                          class="dublicate"
                          [ngStyle]="{
                            display: show_map && innerWidth <= 422 ? '' : 'none'
                          }"
                        >
                          <img
                            src="/assets/images/{{
                              item.booking_status
                                ? 'metre_green.svg'
                                : 'metercube.svg'
                            }}"
                          />
                          {{ item.available_volume }} m³
                        </div>
                      </td>
                      <td
                        [ngStyle]="{
                          display: show_map && innerWidth <= 422 ? 'none ' : ''
                        }"
                      >
                        <img
                          src="/assets/images/{{
                            item.booking_status
                              ? 'metre_green.svg'
                              : 'metercube.svg'
                          }}"
                        />
                        {{ item.available_volume }} m³
                      </td>
                      <td
                        [ngStyle]="{
                          display: show_map && innerWidth <= 422 ? 'none ' : ''
                        }"
                      >
                        {{ item.count + ' ' + selectedValue }}
                      </td>
                      <td
                        [ngStyle]="{
                          display: show_map && innerWidth <= 422 ? 'none ' : ''
                        }"
                      >
                        <!-- <button class="book {{ item.available ? 'close' : '' }}">
                          Calculate Price
                        </button> -->
                        <button
                          mat-stroked-button
                          class="book {{ item.booking_status ? 'close' : '' }}"
                          [disabled]="!item.booking_status || !button_none"
                          (click)="booking(item)"
                        >
                          Calculate Price
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div *ngIf="nodataavail" class="empty_message">
                  <p>{{ messsage }}</p>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center information mt-4"
            >
              <p class="m-0">
                Special requirements? Please call us. Happy to Help..
              </p>
              <mat-icon class="mr-2">phone_in_talk</mat-icon>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row pt-md-3 pt-lg-5" *ngIf="show_payment">
              <div
                class="col-md-12 col-lg-4 mt-3 mt-lg-0 col-12 Price_block px-lg-5 text-center"
              >
                <div>
                  <h4>Total Charges</h4>
                  <h3>₹ {{ price_obj.travel_charge }}</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting
                  </p>
                  <h4>Booking Advance</h4>
                  <h3>₹ {{ price_obj.advance }}</h3>
                  <button mat-stroked-button (click)="paynow()">Pay now</button>
                </div>
              </div>
              <div class="col-md-12 col-lg-8 col-12">
                <div class="blue_block {{ ispay ? '' : 'disabled' }}">
                  <div class="row mt-3 mt-lg-0 confirmation p-lg-5">
                    <div class="col-md-4 payment_method my-1">
                      <h4>Select payment method</h4>
                      <div class="selection d-flex align-items-center">
                        <div
                          class="image_selection {{
                            isChecked ? '' : 'active'
                          }} w-100"
                          id="qrcode"
                        >
                          <img src="/assets/images/qrcode.svg" alt="QR code" />
                        </div>
                        <div class="toggle_button w-100">
                          <mat-slide-toggle
                            [(ngModel)]="isChecked"
                            [disabled]="ispay ? false : true"
                          ></mat-slide-toggle>
                        </div>
                        <div
                          class="image_selection {{
                            isChecked ? 'active' : ''
                          }} w-100"
                          id="bank"
                        >
                          <img src="/assets/images/bank.svg" alt="bank" />
                        </div>
                      </div>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been
                      </p>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center my-1">
                      <div
                        class="d-flex align-items-center justify-content-center"
                        *ngIf="!isChecked"
                      >
                        <div class="qrcode">
                          <img
                            src="/assets/images/sampleqr.svg"
                            alt="QR code"
                            class="w-100"
                          />
                        </div>
                      </div>
                      <div *ngIf="isChecked" class="bank_details">
                        <h4>Bank Details</h4>
                        <table>
                          <tbody>
                            <tr>
                              <td>Account number</td>
                              <td>:</td>
                              <td>{{ account }}</td>
                            </tr>
                            <tr>
                              <td>Ifsc code</td>
                              <td>:</td>
                              <td>{{ ifsccode }}</td>
                            </tr>
                            <tr>
                              <td>Bank name</td>
                              <td>:</td>
                              <td>{{ bank_name }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-4 pay_cta my-1">
                      <button
                        mat-stroked-button
                        [disabled]="ispay ? false : true"
                        (click)="confirm_pay()"
                      >
                        Confirm payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="what_we_do">
  <div class="container">
    <div class="row">
      <div class="col-md-6 my-3 mt-lg-0">
        <div class="pr-lg-2">
          <div class="title">
            <h2>What We Do</h2>
            <h4>Ship hassle-free with our shipping platform.</h4>
          </div>
          <div class="text">
            <p class="sub_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              quis egestas nunc. Sed in augue id tortor finibus tincidunt. Fusce
              dignissim justo vitae ex interdum ultrices. Sed faucibus eu lectus
              in bibendum. Nunc ullamcorper aliquam turpis, eget placerat libero
              hendrerit nec. Morbi vestibulum pharetra egestas. Mauris facilisis
              tellus purus. Donec iaculis tincidunt dignissim.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 pl-lg-2">
        <div class="row">
          <div
            class="col-md-6 p-md-1 p-lg-4 col-lg-6 p-4"
            *ngFor="let item of wedolist"
          >
            <div
              class="icon d-flex align-items-end"
              [ngStyle]="{
                margin: innerWidth <= 767 ? 'auto' : ''
              }"
            >
              <img src="{{ item.img_url }}" class="object-fit" />
            </div>
            <div class="description mt-4">
              <h2>{{ item.title }}</h2>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="solution">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mt-md-5 py-md-4">
        <div class="image_section my-4 my-lg-0">
          <img src="/assets/images/delivery.svg" alt="solutions" />
        </div>
      </div>
      <div class="col-md-6 mt-md-5 py-md-4 p-lg-5 p-md-3">
        <div class="title">
          <h2>Logistics Solutions</h2>
        </div>

        <div class="text">
          <p class="sub_text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            quis egestas nunc. Sed in augue id tortor finibus tincidunt. Fusce
            dignissim justo vitae ex interdum ultrices. Sed faucibus eu lectus
            in bibendum. Nunc ullamcorper aliquam turpis, eget placerat libero
            hendrerit nec.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="train">
    <img src="../assets/images/Train.svg" alt="" />
  </div> -->
</section>

<footer id="footer" class="py-3">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 d-flex justify-content-sm-center justify-content-md-start"
      >
        <p class="m-0">
          Copyright © 2021 6Solve IT Services.All rights reserved.
        </p>
      </div>
      <div
        class="col-md-6 d-flex justify-content-sm-center justify-content-md-end"
      >
        <span>Designed by</span>
        <p class="m-0">
          <a href="http:\\www.6solve.com" class="web_link">
            6Solve IT Services
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>
