import {
  Component,
  HostListener,
  Inject,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core'
import { title } from 'process'
import { DOCUMENT, ViewportScroller } from '@angular/common'
import { BehaviorSubject } from 'rxjs'
import { Observable, from, Subject, of } from 'rxjs'
import { MyErrorStateMatcher, DialogData, environment } from './trainfrom.model'
import * as mapboxgl from 'mapbox-gl'
// import { MatAutocompleteTrigger } from '@angular/material'
import { TokenService } from './service/token.service'
import { UserService } from './service/user.service'
// import PopupComponent from './popup/popup.component'
import {
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  FormControl,
  Validators,
} from '@angular/forms'

import { PopupComponent } from './popup/popup.component'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { LogicService } from './service/logic.service'

// import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /*auto complete */
  // @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger
  // myControl = new FormControl()
  // activeOption
  // options: string[] = ['One', 'Two', 'Three']

  // setValue() {
  //   let options = this._auto.autocomplete.options.toArray()
  //   this.myControl.setValue(options[1].value)
  // }

  /* Mapbox */
  map: mapboxgl.Map
  style = 'mapbox://styles/mapbox/streets-v11'
  lat = 13.0827
  lng = 80.2707
  /* Mapbox  end*/
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth
  }
  messsage = ''
  // count_with_unit
  isChecked = false
  error = false
  price_obj: any = {}
  isActive = false
  available = true
  date_place = true
  booking_id
  search_submitted = false
  check_submitted = false
  from_flag = false
  unit_of_pack = ''
  to_flag = false
  login_flag: boolean = false
  login_flag$: Observable<boolean>
  size_value
  pre_type
  loader_flag$: Observable<boolean>
  loader = false
  nodataavail = false
  toggled = 'qrcode'
  Total_amount = '18500'
  booking_advance = '10000'
  from_location: any = {}
  to_location: any = {}
  from_list: any = []
  to_list: any = []
  public button_type = ''
  amount = 2500
  selectedValue
  show_map = false
  show_message = false
  show_table = false
  show_payment = false
  button_none = false
  minDate
  month
  day
  year
  ispay = false
  marker_array = []
  min_date
  directions
  trainForm: FormGroup
  cargoform: FormGroup
  bank_name = 'Some bank of india'
  account = '6789987656579'
  ifsccode = 'BIN9879870'
  // matcher = new MyErrorStateMatcher()
  public innerWidth: any
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewPortScroller: ViewportScroller,
    public dialog: MatDialog,
    private elref: ElementRef,
    private ls: LogicService,
    private token: TokenService,
    public user: UserService,
    public dialogRef: MatDialogRef<PopupComponent>,
    private ref: ChangeDetectorRef,
  ) {
    // this.user.loginFn = this.login_success
  }

  packages = [
    { value: 'Carton', viewValue: 'Carton' },
    { value: 'Sack', viewValue: 'Sack' },
    { value: 'Crate', viewValue: 'Crate' },
  ]
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loader_flag$ = this.user.loader
    this.loader_flag$.subscribe((result) => {
      console.log(result)
      this.loader = result
      this.loader_trigger(result)
    })
    this.login_flag$ = this.user.listSize
    this.login_flag$.subscribe((result) => {
      console.log(result)
      this.login_flag = result
    })
    this.trainForm = new FormGroup({
      from: new FormControl('', [Validators.required]),
      to: new FormControl('', [Validators.required]),
      date: new FormControl(''),
    })
    this.cargoform = new FormGroup({
      type: new FormControl('', [Validators.required]),
      package_type: new FormControl('', [Validators.required]),
      length: new FormControl('', [Validators.required]),
      breath: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required]),
      count: new FormControl('', [Validators.required]),
      load: new FormControl('', [Validators.required]),
      unit: new FormControl(),
    })
    this.cargoform.controls.unit.setValue('Kg')
    this.innerWidth = window.innerWidth
    this.minDate = new Date()
    this.month = this.minDate.getMonth() + 1 // getMonth() is zero-based
    this.day = this.minDate.getDate() + 2
    this.year = this.minDate.getFullYear()
    if (this.month < 10) {
      this.month = '0' + this.month.toString()
    }

    if (this.day < 10) {
      this.day = '0' + this.day.toString()
    }
    this.min_date = this.year + '-' + this.month + '-' + this.day
    console.log(this.min_date)
    document.getElementById('datepicker').setAttribute('min', this.min_date)
    console.log(this.min_date)

    /*mapbox*/
    mapboxgl.accessToken = environment.mapbox.accessToken
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 10,
      // center: [this.lng, this.lat],
    })

    /*Mapbox*/
    // Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl())
    document.addEventListener('click', this.remove_class)
    console.log('login_flag', this.login_flag)
    let token = this.token.getToken()
    if (token != null) {
      this.login_flag = true
      console.log('login_flag', this.login_flag)
    }

    this.user
      .get_config('account_number')
      .then((res) => {
        if (res.status) {
          this.account = res.data.content_value
        }
      })
      .catch((err) => {
        console.log(err)
      })

    this.user
      .get_config('IFSC_code')
      .then((res) => {
        if (res.status) {
          this.ifsccode = res.data.content_value
        }
      })
      .catch((err) => {
        console.log(err)
      })

    this.user
      .get_config('bank_name')
      .then((res) => {
        if (res.status) {
          this.bank_name = res.data.content_value
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  /** Error when invalid control is dirty, touched, or submitted. */
  get errorControl() {
    // console.log(this.registerForm.controls)
    // console.log(this.forgotForm.controls)
    return this.trainForm.controls
  }

  loader_trigger(flag) {
    if (flag) {
      this.document.getElementById('loader').style.display = 'flex'
    } else {
      this.document.getElementById('loader').style.display = 'none'
    }
  }

  login_success() {
    console.log('logged_in')
    console.log(this.login_flag)
    this.login_flag = true
    // this.login_flag = false
    this.ref.detectChanges()
    console.log(this.login_flag)
    // this.subscription = this.user.getEmittedValue()
    //   .subscribe(item => this.login_flag=item);
  }
  paynow() {
    let user = this.token.getUser()
    let data = {
      train_id:
        this.price_obj.transport_type == 'road'
          ? null
          : this.price_obj.vehicle_id,
      // order_id: "",
      // transaction_id: '',
      // status: 'pending',
      email: user.email,
      user_name: user.name,
      from_location: this.from_location.place_name,
      to_location: this.to_location.place_name,
      cargo: '',
      vehicle_type: this.price_obj.vehicle,
      booking_date: this.price_obj.date,
      count: this.cargoform.value.count,
      from_station: this.price_obj.from_station,
      to_station: this.price_obj.to_station,
      train_number:
        this.price_obj.transport_type == 'road'
          ? null
          : this.price_obj.train_number,
      train_type:
        this.price_obj.transport_type == 'road' ? null : this.price_obj.vehicle,
      truck: this.price_obj.truck,
      cost: this.price_obj.travel_charge,
      booking_weight: this.price_obj.load,
      booking_volume: this.price_obj.volume,
      available_weight: this.price_obj.available_weight,
      available_volume: this.price_obj.available_volume,
      distance: this.price_obj.distance,
      load: this.price_obj.load,
      travel_type: this.price_obj.transport_type,
      vehicle_id: this.price_obj.vehicle_id,
      user_id: user.id,
      // payment_status: 'pending',
    }

    this.ls
      .paying(data)
      .then((res) => {
        if (res.status) {
          this.ispay = true
          localStorage.removeItem('booking_id')
          localStorage.setItem('booking_id', res.data.id)
          this.booking_id = res.data.id
          console.log(res)
          console.log(this.booking_id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    this.ispay = true
  }
  removeclass() {
    if ((this.date_place = true)) {
      this.date_place = false
    }
  }
  get cargoControl() {
    return this.cargoform.controls
  }
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    )
  }

  // from = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher()
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.documentElement.scrollTop > 0.2) {
      document.getElementById('header').classList.add('sticky')
    }

    if (window.scrollY == 0) {
      document.getElementById('header').classList.remove('sticky')
    }
  }

  availabilitylist: any = [
    {
      date: '26/12/2021',
      available_weight: '1500',
      available_volume: '2500',
      booking_status: true,
      dummy: true,
      count: 3,
    },
    {
      date: '26/12/2021',
      available_weight: '1500',
      available_volume: '2500',
      booking_status: true,
      count: 3,
    },
    {
      date: '26/12/2021',
      available_weight: '1500',
      available_volume: '2500',
      booking_status: true,
      count: 3,
    },
    {
      date: '26/12/2021',
      available_weight: '1500',
      available_volume: '2500',
      booking_status: true,
      count: 3,
    },
    {
      date: '26/12/2021',
      available_weight: '1500',
      available_volume: '2500',
      booking_status: true,
      count: 3,
    },
  ]
  // = [
  //   {
  //     date: '26/12/2021',
  //     weight: '1500',
  //     cubicmetre: '2500',
  //     available: true,
  //   },
  //   {
  //     date: '26/12/2021',
  //     weight: '1500',
  //     cubicmetre: '2500',
  //     available: true,
  //   },
  //   {
  //     date: '26/12/2021',
  //     weight: '1500',
  //     cubicmetre: '2500',
  //     available: true,
  //   },
  //   {
  //     date: '26/12/2021',
  //     weight: '1500',
  //     cubicmetre: '2500',
  //     available: false,
  //   },
  //   {
  //     date: '26/12/2021',
  //     weight: '1500',
  //     cubicmetre: '2500',
  //     available: false,
  //   },
  // ]
  searchlist = [
    // {
    //   city: 'Tirunelveli',
    //   detail:
    //     'Dindigul Thalapakatti Restaurant,Besant Nagar,Chennai,Tamil Nadu',
    // },
    // {
    //   city: 'Tirunelveli',
    //   detail:
    //     'Dindigul Thalapakatti Restaurant,Besant Nagar,Chennai,Tamil Nadu',
    // },
    // {
    //   city: 'Tirunelveli',
    //   detail:
    //     'Dindigul Thalapakatti Restaurant,Besant Nagar,Chennai,Tamil Nadu',
    // },
    // {
    //   city: 'Tirunelveli',
    //   detail:
    //     'Dindigul Thalapakatti Restaurant,Besant Nagar,Chennai,Tamil Nadu',
    // },
    // {
    //   city: 'Tirunelveli',
    //   detail:
    //     'Dindigul Thalapakatti Restaurant,Besant Nagar,Chennai,Tamil Nadu',
    // },
  ]
  wedolist = [
    {
      img_url: '/assets/images/rocket.svg',
      title: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet,consectetur adipiscing elit.',
    },
    {
      img_url: '/assets/images/chart.svg',
      title: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet,consectetur adipiscing elit.',
    },
    {
      img_url: '/assets/images/setting.svg',
      title: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet,consectetur adipiscing elit.',
    },
    {
      img_url: '/assets/images/truck.svg',
      title: 'Lorem Ipsum',
      text: 'Lorem ipsum dolor sit amet,consectetur adipiscing elit.',
    },
  ]

  calculate_size(e, event) {
    if (e == 'true') {
      // document.getElementById('size_input').removeEventListener('click')
      // document.getElementById('size_input').removeAttribute('onclick')
      document.getElementById('size_input').classList.add('show')
      console.log('focus')
    } else {
      // document.getElementById('size_input').createAttribute('onclick')
      // document.getElementById('size_input').addEventListener('click')
      document.getElementById('size_input').classList.remove('show')
      console.log('out')
    }
  }
  dimension_cal() {
    let length = 0
    let height = 0
    let breadth = 0
    if (
      this.cargoform.value.breath != '' &&
      this.cargoform.value.breath != null
    ) {
      breadth = this.cargoform.value.breath
    }
    if (
      this.cargoform.value.height != '' &&
      this.cargoform.value.height != null
    ) {
      height = this.cargoform.value.height
    }
    if (
      this.cargoform.value.length != '' &&
      this.cargoform.value.length != null
    ) {
      length = this.cargoform.value.length
    }
    if (length != 0 && height != 0 && breadth != 0) {
      this.size_value = length + '*' + breadth + '*' + height
    } else {
      this.size_value = ''
    }
    // console.log(this.cargoform)
  }

  remove_class(event) {
    let arr = ['size_input', 'input_size']
    if (!arr.includes(event.target.id)) {
      document.getElementById('size_input').classList.remove('show')
      // let length = 0
      // let height = 0
      // let breadth = 0
      // if (this.cargoform.value.breath != '') {
      //   breadth = this.cargoform.value.breath
      // }
      // if (this.cargoform.value.height != '') {
      //   height = this.cargoform.value.height
      // }
      // if (this.cargoform.value.length != '') {
      //   length = this.cargoform.value.length
      // }
      // if (length != 0 && height != 0 && breadth != 0) {
      //   this.size_value = length + '*' + breadth + '*' + height
      // } else {
      //   this.size_value = ''
      // }
    }
  }

  select(e) {
    console.log(e)
    if (e.target.value == '') {
      this.date_place = true
    } else {
      this.date_place = false
    }
  }

  toggleAccordian(event) {
    this.search_submitted = true
    const element = event.target.parentElement
    console.log(this.errorControl)
    if (this.trainForm.status == 'VALID') {
      if (!this.isActive) {
        this.isActive = true
        this.show_map = true
      } else {
        // this.isActive = false
        // this.show_map = false
        console.log('map refreshed')
      }
      const panel = this.document.getElementById('panel') //element.nextElementSibling
      if (panel.style.maxHeight) {
        // panel.style.maxHeight = null
      } else {
        panel.style.maxHeight = 2200 + 'px'
      }
    }
    // this.map = new mapboxgl.Map()
    // Create a default Marker and add it to the map.
    let marker1, marker2
    this.marker_array.forEach((element) => {
      element.remove()
    })
    marker1 = new mapboxgl.Marker({ color: 'red' })
      .setLngLat(this.from_location.center)
      .addTo(this.map)
    this.marker_array.push(marker1)

    // Create a default Marker, colored black, rotated 45 degrees.
    marker2 = new mapboxgl.Marker({ color: 'red' })
      .setLngLat(this.to_location.center)
      .addTo(this.map)
    this.marker_array.push(marker2)

    // var group = new mapboxgl.featureGroup([marker1, marker2])

    // this.map.fitBounds(group.getBounds())
    // var bounds = latLngBounds(this.marker_array)
    console.log(marker1._lngLat.lat, marker1._lngLat.lng)
    console.log(marker2._lngLat.lat, marker2._lngLat.lng)
    this.map.fitBounds(
      [
        [marker1._lngLat.lng, marker1._lngLat.lat],
        [marker2._lngLat.lng, marker2._lngLat.lat],
      ],
      { padding: 100 },
    )
  }

  title = 'keylogistics'

  openDialog(e): void {
    localStorage.removeItem('key_type')
    localStorage.setItem('key_type', e)
    this.dialogRef = this.dialog.open(PopupComponent, {
      width: e == 'success' ? '40rem' : '23rem',
      // width: '23rem',
      // overflow: 'auto',
      // button_type: e,
    })

    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
      // this.animal = result
    })
  }
  // openDialog(): void {
  //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '250px',
  //     data: {name: this.name, animal: this.animal},
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }

  auto_complete(e, type, flag) {
    this.pre_type = type
    var code = e.keyCode ? e.keyCode : e.which
    // if(code == 13) { //Enter keycode
    // alert('enter press');
    // }
    // console.log(code)
    // console.log(flag)
    if (
      (e.target.value.length > 4 && type == 'from' && flag) ||
      (this.pre_type == 'from' && e.target.value.length > 2 && code == 13)
    ) {
      this.ls.search_location(e.target.value).then((res) => {
        this.from_list = res.features
        this.from_list.forEach((element) => {
          let arr = []
          // console.log(element.place_name.split(','))
          // console.log(element)
          element.place = element.place_name.split(',')[0]
          element.location_name = element.place_name.split(',')[1]
          arr.push(element.text)

          for (let i = 2; i < element.place_name.split(',').length - 1; i++) {
            // console.log(arr)
            // console.log(element.context[i].text)
            if (!arr.includes(element.place_name.split(',')[i])) {
              arr.push(element.place_name.split(',')[i])
              element.location_name =
                element.location_name + ',' + element.place_name.split(',')[i]
            }
          }
        })
        if (this.from_list.length > 0) {
          console.log(this.from_list)
          this.from_flag = true
        } else {
          this.from_list = []
          this.from_flag = false
        }
      })
    } else if (
      (e.target.value.length > 4 && type == 'to' && flag) ||
      (this.pre_type == 'to' && e.target.value.length > 2 && code == 13)
    ) {
      this.ls.search_location(e.target.value).then((res) => {
        this.to_list = res.features
        res.features.forEach((element) => {
          let arr = []
          element.place = element.place_name.split(',')[0]
          element.location_name = element.place_name.split(',')[1]
          arr.push(element.text)

          for (let i = 2; i < element.place_name.split(',').length - 1; i++) {
            // console.log(arr)
            // console.log(element.context[i].text)
            if (!arr.includes(element.place_name.split(',')[i])) {
              arr.push(element.place_name.split(',')[i])
              element.location_name =
                element.location_name + ',' + element.place_name.split(',')[i]
            }
          }
        })
        // console.log(res)
        if (this.to_list.length > 0) {
          this.to_flag = true
        } else {
          this.to_flag = false
        }
      })
    } else {
      if (type == 'from') {
        this.from_list = []
        this.from_flag = false
      } else {
        this.to_list = []
        this.to_flag = false
      }
    }
  }

  select_location(e, type) {
    if (type == 'from') {
      // this.trainForm.value.from = e.text
      this.from_location = e
      this.from_flag = false
    } else {
      this.to_location = e
      this.to_flag = false
    }
    console.log(e)
  }
  append_unit() {
    if (this.cargoform.value.package_type != '') {
      this.cargoform.value.count =
        this.cargoform.value.count + this.cargoform.value.package_type
    }
  }
  refresh_availability() {
    console.log('cargo')
    console.log(this.cargoform.value)
    let same_state = false
    this.check_submitted = true

    if (this.cargoform.value.package_type == 'Carton') {
      this.unit_of_pack = 'cartons'
    } else if (this.cargoform.value.package_type == 'Sack') {
      this.unit_of_pack = "sack's"
    } else if (this.cargoform.value.package_type == 'Crate') {
      this.unit_of_pack = "crate's"
    } else {
      this.unit_of_pack = 'packages'
    }

    // console.log(
    //   this.from_location.context[this.from_location.context.length - 2].text,
    // )
    // console.log(
    //   this.to_location.context[this.to_location.context.length - 2].text,
    // )
    if (
      this.from_location.context[this.from_location.context.length - 2].text ==
      this.to_location.context[this.to_location.context.length - 2].text
    ) {
      same_state = true
    }
    if (this.cargoform.status == 'VALID') {
      let data = {
        from: this.from_location.center,
        to: this.to_location.center,
        date:
          this.trainForm.value.date != ''
            ? this.trainForm.value.date
            : this.min_date,
        type: this.cargoform.value.type,
        package_type: this.cargoform.value.package_type,
        length: this.cargoform.value.length / 100,
        breath: this.cargoform.value.breath / 100,
        height: this.cargoform.value.height / 100,
        count: this.cargoform.value.count,
        load:
          this.cargoform.value.unit == 'Kg'
            ? this.cargoform.value.load
            : this.cargoform.value.load * 1000,
        unit: this.cargoform.value.unit,
        same_state: same_state,
      }
      this.loader = true
      this.loader_trigger(true)
      this.ls
        .check_availability(data)
        .then((res) => {
          console.log(res)
          if (res.status == 1) {
            this.show_table = true
            this.show_message = true
            this.button_none = true
            this.nodataavail = false
            let load =
              this.cargoform.value.unit == 'Kg'
                ? this.cargoform.value.load
                : this.cargoform.value.load * 1000
            let weight = load / this.cargoform.value.count
            let volume =
              ((((this.cargoform.value.length / 100) *
                this.cargoform.value.breath) /
                100) *
                this.cargoform.value.height) /
              100

            this.availabilitylist = res.data
            if (this.availabilitylist.length > 0) {
              this.availabilitylist.forEach((elmt) => {
                let weight_count = elmt.available_weight / weight
                let volume_count = elmt.available_volume / volume
                let formatYmd = (date) =>
                  date.toISOString().slice(0, 10).split('-').reverse().join('/')
                // let format_date=formatYmd(elmt.date)
                elmt.date = formatYmd(new Date(elmt.date))
                if (volume_count > weight_count) {
                  elmt.count = parseInt(`${weight_count}`)
                } else {
                  elmt.count = parseInt(`${volume_count}`)
                }
              })
              console.log(this.availabilitylist)
            }
            this.loader = false
            this.loader_trigger(false)
          } else {
            if (res.show_message) {
              this.show_message = false
              this.nodataavail = true
              this.messsage = res.message
              this.loader = false
              this.loader_trigger(false)
            } else {
              // this.show_message = true
              console.log(res)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      console.log('form invalid')
    }
  }

  booking(e) {
    if (this.button_none) {
      let token = this.token.getToken()
      if (token == null) {
        this.openDialog('login')
      } else {
        this.show_payment = true
        this.price_obj = e
        console.log(this.price_obj)
      }
    }
  }
  //   map.on('load', function () {
  //     this.map.addSource("route", {
  //         "type": "geojson",
  //         "data": {
  //             "type": "Feature",
  //             "properties": {},
  //             "geometry": {
  //                 "type": "LineString",
  //                 "coordinates": [
  //                  [-155.088899,19.722942],[-155.08565,19.72472],[-155.084661,19.723701],[-155.083569,19.723139],[-155.079557,19.722262],[-155.074227,19.721938],[-155.069939,19.722545],[-155.070061,19.721225],[-155.07007,19.711726]
  //                 ]
  //             }
  //         }
  //     });

  //     this.map.addLayer({
  //         "id": "route",
  //         "type": "line",
  //         "source": "route",
  //         "layout": {
  //             "line-join": "round",
  //             "line-cap": "round"
  //         },
  //         "paint": {
  //             "line-color": "#888",
  //             "line-width": 8
  //         }
  //     });
  // });
  // function() {
  //   this.directions.setOrigin(start);
  //   this.directions.addWaypoint(0, [-0.07571203, 51.51424049]);
  //   this.directions.addWaypoint(1, [-0.12416858, 51.50779757]);
  //   this.directionsthis.directions.setDestination(end);
  // }
  hide_cms(e) {
    if (e.target.value != '') {
      // e.target.parentElement.classList.remove('input')
      console.log(e.target.parentElement)
    } else {
      // e.target.parentElement.classList.add('input')
    }
  }
  log_out() {
    this.token.signOut()
    this.login_flag = false
  }

  confirm_pay() {
    this.openDialog('transaction')
  }

  update_transaction() {
    let user = this.token.getUser()
    let id = localStorage.getItem('booking_id')
    let tran = localStorage.getItem('transaction_id')
    let data = {
      booking_id: id,
      user_id: user.id,
      transaction_id: tran,
    }
    this.ls.update_transaction(data).then((res) => {
      console.log(res)
      if (res.status) {
        // location.reload()
        localStorage.setItem('order_id', res.order_id)
        this.openDialog('success')
      }
    })
  }
}
