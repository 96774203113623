import { Component, HostListener, OnInit, Inject } from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms'
import { AppComponent } from '../app.component'
// import { inject } from '@angular/core/testing'
import { DialogData } from '../trainfrom.model'
import { UserService } from '../service/user.service'
import { TokenService } from '../service/token.service'

// import { DialogData } from './trainfrom.model'
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth
  }
  servicemessage
  form_type = 'Password'
  button_type = ''
  order_number
  pass_visiblity = 'visibility_off'
  confirm_visiblity = 'visibility_off'
  otp_request = false
  otp_message
  show_error = false
  show_otp_error = false
  show_password_error = false
  passwordmessage
  transaction_id = ''
  submit_register = false
  registerForm: FormGroup
  loginForm: FormGroup
  otpLoginForm: FormGroup

  innerWidth
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    public myapp: AppComponent, // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { width: string },
    public user: UserService,
    public token: TokenService,
  ) {}

  onNoClick(): void {
    this.dialogRef.close()
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth
    // this.innerWidth = this.innerWidth
    // this.registerForm.reset()
    // this.loginForm.reset()
    this.order_number = localStorage.getItem('order_id')
    console.log(this.innerWidth)
    this.button_type = localStorage.getItem('key_type')
    this.registerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      phone: new FormControl('', [Validators.required]),
      pin: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      gst: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    })
    this.registerForm.reset()
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      password: new FormControl('', [Validators.required]),
    })
    this.otpLoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      otp: new FormControl('', [Validators.required]),
    })
  }

  get regitserControl() {
    return this.registerForm.controls
  }

  get loginControl() {
    return this.loginForm.controls
  }

  get otpLoginControl() {
    return this.otpLoginForm.controls
  }

  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    )
  }

  select_form(e) {
    console.log(e)
    this.form_type = e
  }

  openDialog(e): void {
    if (e == 'login') {
      this.button_type = 'login'
    } else if (e == 'register') {
      this.button_type = 'register'
      this.registerForm.reset()
    } else {
      this.button_type = 'forgotpassword'
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
      // this.animal = result
    })
  }

  show_pass(e, field) {
    if (field == 'password') {
      if (this.pass_visiblity != 'visibility_off') {
        this.pass_visiblity = 'visibility_off'
      } else {
        this.pass_visiblity = 'visibility'
      }
    } else {
      if (this.confirm_visiblity != 'visibility_off') {
        this.confirm_visiblity = 'visibility_off'
      } else {
        this.confirm_visiblity = 'visibility'
      }
    }
  }

  register_user() {
    console.log(this.registerForm)
    this.submit_register = true

    if (
      this.registerForm.value.password !=
      this.registerForm.value.confirm_password
    ) {
      this.registerForm.controls.confirm_password.setErrors({
        notEquivalent: true,
      })
    }
    if (this.registerForm.status == 'VALID') {
      this.user.load_trigger(true)
      let data = {
        name: this.registerForm.value.name,
        email: this.registerForm.value.email,
        phone: this.registerForm.value.phone,
        password: this.registerForm.value.password,
        address: this.registerForm.value.address,
        pin: this.registerForm.value.pin,
        gst_number: this.registerForm.value.gst,
      }
      this.user
        .register(data)
        .then((res) => {
          if (res.status == 1) {
            this.user.load_trigger(false)
            console.log(res)
            this.button_type = 'login'
            this.form_type = 'Password'
            console.log('registered successfully')
            this.registerForm.reset()
          } else if (res.phone_unique || res.email_unique) {
            this.user.load_trigger(false)
            // console.log(res)
            this.show_error = true
            this.servicemessage = res.message
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      console.log('invalid form')
    }
  }

  send_otp() {
    console.log(this.otpLoginControl.email)
    let data = {
      email: this.otpLoginForm.value.email,
    }
    if (this.otpLoginControl.email.errors == null) {
      this.user.send_otp(data).then((res) => {
        console.log(res)
        if (res.status == 1) {
          this.otp_request = true
        } else if (res.email_exist) {
          this.show_otp_error = true
          this.otp_message = res.message
          console.log('error')
        }
      })
    } else {
      console.log('please enter email')
    }
  }

  login() {
    console.log(this.loginForm)

    let data = {
      email:
        this.form_type == 'Password'
          ? this.loginForm.value.email
          : this.otpLoginForm.value.email,
      password: this.loginForm.value.password,
      otp: this.otpLoginForm.value.otp,
      login_type: this.form_type,
    }
    if (this.form_type == 'Password') {
      if (this.loginForm.status == 'VALID') {
        this.user.load_trigger(true)
        this.user
          .login(data)
          .then((res) => {
            if (res.status == 1) {
              this.user.load_trigger(false)
              this.token.saveToken(res.token)
              this.token.saveUser(res.user)
              // this.myapp.login_success()
              this.user.login_success()
              // this.user.loginFn()
              this.dialogRef.close()
            } else if (res.email_exist || res.pass_match) {
              this.user.load_trigger(false)
              this.show_password_error = true
              this.passwordmessage = res.message
            }
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        console.log('invalid login form')
      }
    } else {
      if (this.otpLoginForm.status == 'VALID') {
        this.user.load_trigger(true)
        this.user
          .login(data)
          .then((res) => {
            console.log(res)
            if (res.status == 1) {
              this.user.load_trigger(false)
              this.token.saveToken(res.token)
              this.token.saveUser(res.user)
              this.user.login_success()
              // this.myapp.logged_in = true
              // this.myapp.login_success()
              // this.user.loginFn()
              this.dialogRef.close()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        console.log('invalid otp form')
      }
    }
  }
  update_payment() {
    if (this.transaction_id != '' && this.transaction_id != null) {
      localStorage.setItem('transaction_id', this.transaction_id)
      this.myapp.update_transaction()
    }
  }

  reload() {
    location.reload()
  }
}
