<div id="login" class="p-3" *ngIf="button_type == 'login'">
  <h2 class="text-center mb-4">Login</h2>

  <div class="radio_cta mb-4 text-center">
    <mat-radio-group
      aria-label="Select an option"
      (change)="select_form($event.value)"
    >
      <mat-radio-button
        checked
        value="Password"
        [ngStyle]="{ 'margin-right': '.8rem' }"
      >
        Password
      </mat-radio-button>
      <mat-radio-button value="OTP">Login with OTP</mat-radio-button>
    </mat-radio-group>
  </div>
  <form
    action=""
    [formGroup]="loginForm"
    *ngIf="form_type == 'Password'"
    class="loginform mt-3"
  >
    <span class="error" *ngIf="show_password_error">
      {{ passwordmessage }}
    </span>
    <div class="Username d-grid mb-3">
      <label>Registered email ID</label>
      <mat-form-field appearance="fill">
        <!-- <mat-label>User name</mat-label> -->
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input matInput formControlName="email" placeholder="" type="text" />
      </mat-form-field>
    </div>

    <div class="password d-grid">
      <label>Password</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>lock_open</mat-icon>
        </span>
        <input
          matInput
          formControlName="password"
          placeholder=""
          [type]="pass_visiblity == 'visibility' ? 'text' : 'password'"
        />
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'password')">
            {{ pass_visiblity }}
          </mat-icon>
        </span>
        <!-- <mat-hint align="end" class="forgot">
          <strong>Forgot Password?</strong>
        </mat-hint> -->
      </mat-form-field>
      <div class="d-flex justify-content-end forgot">
        <a (click)="openDialog('forgotpassword')">Forgot Password?</a>
      </div>
    </div>
    <button mat-stroked-button class="mt-3" (click)="login()">Login</button>
    <div class="text-center mt-3">
      <p class="m-0">
        New here?
        <a class="text-center" (click)="openDialog('register')">Register</a>
      </p>
    </div>
  </form>

  <form
    action=""
    [formGroup]="otpLoginForm"
    *ngIf="form_type == 'OTP'"
    class="loginform mt-3"
  >
    <span class="error" *ngIf="show_otp_error">
      {{ otp_message }}
    </span>
    <div class="Username d-grid mb-3">
      <label>Email</label>
      <mat-form-field appearance="fill">
        <!-- <mat-label>User name</mat-label> -->
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input matInput placeholder="" formControlName="email" type="email" />
      </mat-form-field>
    </div>

    <div class="password d-grid" *ngIf="otp_request">
      <label>OTP</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>lock_open</mat-icon>
        </span>
        <input matInput placeholder="" formControlName="otp" type="number" />
      </mat-form-field>
      <div class="d-flex justify-content-end forgot">
        <a>Forgot Password?</a>
      </div>
    </div>
    <button
      mat-stroked-button
      class="mt-3"
      (click)="send_otp()"
      *ngIf="!otp_request"
    >
      Send OTP
    </button>
    <button
      type="submit"
      mat-stroked-button
      class="mt-3"
      *ngIf="otp_request"
      (click)="login()"
    >
      Login
    </button>
    <div class="text-center mt-3">
      <p class="m-0">
        New here?
        <a class="text-center" (click)="openDialog('register')">Register</a>
      </p>
    </div>
  </form>
</div>

<div id="login" class="p-3" *ngIf="button_type == 'forgotpassword'">
  <h2 class="text-center mb-3 forgot_title">Forgot password</h2>

  <form action="" class="loginform mt-3">
    <div class="email d-grid mb-1">
      <label>Email</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>email</mat-icon>
        </span>
        <input matInput placeholder="" type="email" />
      </mat-form-field>
    </div>

    <div class="text-center">
      <button mat-stroked-button class="mb-3">Continue</button>
      <p class="m-0">
        Remember your password ?
        <a class="text-center" (click)="openDialog('login')">Login</a>
      </p>
    </div>
  </form>
</div>

<div id="register" class="px-3" *ngIf="button_type == 'register'">
  <h2 class="text-center">Register</h2>
  <span class="error" *ngIf="show_error">
    {{ servicemessage }}
  </span>
  <form [formGroup]="registerForm" action="" class="registerform mt-3">
    <div class="Username d-grid mb-1">
      <label>Name</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input matInput formControlName="name" placeholder="" type="text" />
        <!-- <span
          class="error"
          *ngIf="regitserControl.name.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>
    <div class="email d-grid mb-1">
      <label>Email</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>email</mat-icon>
        </span>
        <input matInput formControlName="email" placeholder="" type="email" />
        <!-- <span
          class="error"
          *ngIf="regitserControl.email.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>
    <div class="phone d-grid mb-1">
      <label>Phone number</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>phone_in_talk</mat-icon>
        </span>

        <input
          matInput
          formControlName="phone"
          placeholder=""
          type="text"
          [maxlength]="10"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
        <!-- <span
          class="error"
          *ngIf="regitserControl.phone.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>
    <div
      class="align-items-end {{
        innerWidth <= 420 ? 'd-grid' : 'd-flex'
      }} address"
    >
      <div
        class="address d-grid mb-1"
        [ngStyle]="{ width: innerWidth <= 420 ? '100%' : '43%' }"
      >
        <label>Address</label>
        <mat-form-field appearance="fill">
          <span matPrefix>
            <mat-icon>location_on</mat-icon>
          </span>
          <input
            matInput
            formControlName="address"
            placeholder=""
            type="text"
          />
        </mat-form-field>
      </div>
      <div
        class="pin mb-1"
        [ngStyle]="{
          width: innerWidth <= 420 ? '100%' : '26%',
          margin: innerWidth <= 420 ? '0 0 0 0' : '0 0 0 31%'
        }"
      >
        <label>Pincode</label>
        <mat-form-field appearance="fill">
          <input
            matInput
            formControlName="pin"
            placeholder=""
            type="number"
            [maxlength]="6"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </mat-form-field>
      </div>
      <!-- <span
        class="error"
        *ngIf="regitserControl.address.errors && submit_register"
      >
        This field is required
      </span> -->
      <!-- <span class="error" *ngIf="regitserControl.pin.errors && submit_register">
        This field is required
      </span> -->
    </div>
    <div class="gst d-grid mb-1">
      <label>GST number</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>developer_board</mat-icon>
        </span>
        <input matInput formControlName="gst" placeholder="" type="text" />
        <!-- <span
          class="error"
          *ngIf="regitserControl.gst.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>

    <div class="Username d-grid mb-1">
      <label>Password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'password')">
            {{ pass_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="password"
          [type]="pass_visiblity == 'visibility' ? 'text' : 'password'"
        />
        <!-- <span
          class="error"
          *ngIf="regitserControl.password.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>
    <div class="Username d-grid mb-1">
      <label>Confirm password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'confirm')">
            {{ confirm_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="confirm_password"
          [type]="confirm_visiblity == 'visibility' ? 'text' : 'password'"
        />
        <!-- <span
          class="error"
          *ngIf="regitserControl.confirm_password.errors && submit_register"
        >
          This field is required
        </span> -->
      </mat-form-field>
    </div>
    <button mat-stroked-button class="mt-3" (click)="register_user()">
      Register
    </button>
    <div class="text-center mt-3">
      <p class="m-0">
        Already have an account?
        <a class="text-center" (click)="openDialog('login')">Login</a>
      </p>
    </div>
  </form>
</div>

<div id="successpopup" *ngIf="button_type == 'success'">
  <div class="icon">
    <mat-icon (click)="this.dialogRef.close()">cancel</mat-icon>
  </div>
  <div class="text-center">
    <p>Thank you for placing your order {{ order_number }} with us.</p>
    <p>We will confirm through email as soon as the payment is released.</p>
  </div>
  <div class="text-center">
    <button mat-stroked-button (click)="reload()">Ok</button>
  </div>
</div>

<div id="transactionpopup" *ngIf="button_type == 'transaction'">
  <div class="icon">
    <mat-icon (click)="this.dialogRef.close()">cancel</mat-icon>
  </div>
  <div class="text-center">
    <h4>Enter Transcation ID</h4>
    <!-- <form class="example-form"> -->
    <mat-form-field class="transaction" appearance="fill">
      <input [(ngModel)]="transaction_id" type="text" matInput />
    </mat-form-field>
    <div class="text-center">
      <button mat-stroked-button (click)="update_payment()">Ok</button>
    </div>
    <!-- </form> -->
  </div>
</div>
