import { Injectable } from '@angular/core'
import { url } from './config'
import { HttpHeaders } from '@angular/common/http'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, from, Subject, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private listSizeSubject: Subject<boolean>
  private _listSize: Observable<boolean>
  private loadersubject: Subject<boolean>
  private _loader: Observable<boolean>
  constructor(private http: HttpClient) {
    this.loadersubject = new Subject()
    this._loader = this.loadersubject.asObservable()
    this.listSizeSubject = new Subject()
    this._listSize = this.listSizeSubject.asObservable()
  }

  async register(data): Promise<any> {
    return await this.http.post(`${url}userApi/register`, data).toPromise()
  }

  async send_otp(data): Promise<any> {
    return await this.http.post(`${url}userApi/send_otp`, data).toPromise()
  }

  async login(data): Promise<any> {
    return await this.http.post(`${url}userApi/login`, data).toPromise()
  }

  async get_config(data): Promise<any> {
    return await this.http
      .post(`${url}userApi/get_config`, { content_key: data })
      .toPromise()
  }

  get listSize() {
    return this._listSize
  }

  async login_success() {
    this.listSizeSubject.next(true)
  }

  get loader() {
    return this._loader
  }

  async load_trigger(flag) {
    this.loadersubject.next(flag)
  }
}
